<template>
  <v-form ref="cardForm" class="cardForm">
    <v-row>
      <v-col cols="12" sm="12" md="6">
        <v-text-field
          v-model="currentForm.clientName"
          outlined
          dense
          :disabled="isDisabled"
          :rules="rules.emptyRules"
          label="Ονοματεπώνυμο Παραλήπτη"
          color="orange"
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-text-field
          type="number"
          v-model="currentForm.clientMobile"
          outlined
          dense
          :disabled="isDisabled"
          :rules="rules.mobile"
          label="Τηλέφωνο Επικοινωνίας"
          color="orange"
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="12">
        <v-textarea
          rows="2"
          dense
          outlined
          :disabled="isDisabled"
          v-model="currentForm.clientExtraDetails"
          label="Λεπτομέρειες παράδοσης(πχ. κουδούνι, όροφος κλπ)"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row style="margin: 0px" v-if="!isDisabled">
      <v-spacer></v-spacer>
      <v-btn
        text
        @click="$emit('closeForm')"
        class="m-r-10 font-weight-bold"
        style="text-transform: initial"
      >
        Άκυρο
      </v-btn>
      <v-btn
        @click="saveForm"
        color="orange"
        style="text-transform: initial; color: white"
      >
        Αποθήκευση
      </v-btn>
    </v-row>
  </v-form>
</template>

<script>
import { cloneDeep } from "lodash";
import { validateMobile } from "@/common-js/validations";
import { mapActions } from "vuex";
export default {
  props: {
    voucherData: {
      type: Object,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentForm: {
        clientName: null,
        clientMobile: null,
        clientExtraDetails: null,
        clientPostalCode: null,
      },
      rules: {
        emptyRules: [(v) => !!v || "Το πεδίο είναι υποχρεωτικό"],
        mobile: [
          (v) => !!v || "Το πεδίο είναι υποχρεωτικό",
          (v) => validateMobile(v) || "Το πεδίο πρέπει να είναι τηλέφωνο",
        ],
      },
    };
  },
  computed: {},
  methods: {
    ...mapActions({
      changeDetails: "livetracking/livetracking/changeDetails",
    }),
    saveForm() {
      const isValid = this.$refs.cardForm.validate();
      if (isValid) {
        let form = {
          id: this.voucherData._id,
          newClientName: this.currentForm.clientName,
          newClientExtraDetails: this.currentForm.clientExtraDetails,
          newClientPhone:
            validateMobile(this.currentForm.clientMobile) &&
            !String(this.currentForm.clientMobile).startsWith("30")
              ? `30${this.currentForm.clientMobile}`
              : this.currentForm.clientMobile,
        };
        this.changeDetails({
          vm: this,
          requestBody: form,
        });
      }
    },
  },
  created() {
    this.currentForm = cloneDeep(this.voucherData);
  },
};
</script>

<style lang="scss">
.cardForm {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
</style>
